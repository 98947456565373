'use client';

import {
  useCallback,
  useEffect,
  useId,
  useState,
  type FunctionComponent,
} from 'react';
import { Routes } from '@/constants';
import {
  Badge,
  Card,
  CardContent,
  CardMedia,
  CardTitle,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  LayoutGrid,
  LayoutGridContent,
  Typography,
  type CarouselApi,
} from '@motortrend/ids';
import { type EmblaCarouselType, type EmblaEventType } from 'embla-carousel';
import { withErrorBoundary } from 'react-error-boundary';

import { type ImageTemplateProps } from '@/types/ImgSizes';

import { graphql } from '@/lib/gql';
import formatVideoDuration from '@/utils/formatVideoDuration';
import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';
import AkamaiImage, { type AkamaiImageProps } from '@/components/AkamaiImage';
import { NoUiErrorFallback } from '@/components/ErrorFallback';
import { NextLink } from '@/components/NextLink';

import { type FilmStripPackageProps } from './FilmStrip.props';

export const FilmStripPackageFragment = graphql(/* GraphQL */ `
  fragment FilmStripPackage on RoutePackages {
    options {
      title
      showFullWidth
    }
    items {
      title
      videos {
        description
        videoId
        videoName
        slug
        thumbnailUrl
        duration
      }
    }
  }
`);

const FilmStrip: FunctionComponent<FilmStripPackageProps> = ({
  isFullWidth,
  items = [],
  options,
  priority,
}) => {
  const packageId = useId();
  const item = Array.isArray(items) && items.length && items[0];
  const videos = item && item.videos;

  // Use block title or if no block title, use playlist title or empty string
  const title = options?.title || (item && item?.title) || '';

  const hasTitle = !!title;

  const [api, setApi] = useState<CarouselApi>();

  const template: ImageTemplateProps = {
    defaultSize: '40vw',
    sizeConfig: {
      lg: '30vw',
      sm: '40vw',
    },
  };

  const onSelectListener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (api: EmblaCarouselType, event: EmblaEventType) => {
      // do stuff
    },
    [],
  );

  const removeOnSelectListener = useCallback(() => {
    if (!api) {
      return;
    }

    api.off('select', onSelectListener);
  }, [api, onSelectListener]);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on('select', onSelectListener);

    return () => {
      removeOnSelectListener();
    };
  }, [api, onSelectListener, removeOnSelectListener]);

  if (!videos || !videos.length) {
    return null;
  }

  const displayFullWidth = isFullWidth || !!options?.showFullWidth;

  const { heading: headingPriority, image: imagePriority } = priority || {};

  return (
    <LayoutGrid
      aria-labelledby={hasTitle ? packageId : undefined}
      as="section"
      data-package="FilmStrip"
    >
      <LayoutGridContent fullWidth={displayFullWidth}>
        {hasTitle && (
          <Typography
            as={headingPriority ? 'h1' : 'h2'}
            className="pb-4 sm:pb-6"
            id={packageId}
            md="h4"
            variant="h5"
          >
            {title}
          </Typography>
        )}
        <Carousel setApi={setApi}>
          <CarouselContent className="sm:gap-4">
            {videos.map((video) => {
              if (!video?.thumbnailUrl) {
                return null;
              }

              return (
                <CarouselItem key={`CarouselItem-${video?.videoId}`}>
                  <Card
                    as={NextLink}
                    data-id={DataId.FilmStripCard}
                    data-parent={`Film Strip : ${title || 'Unknown'}`}
                    href={normalizeUrl({
                      paths: [Routes.Watch, video?.videoId, video?.slug],
                    })}
                    orientation="vertical"
                  >
                    <CardMedia
                      alt={video?.description?.trim()}
                      as={AkamaiImage}
                      aspectRatio="16/9"
                      imageProps={
                        {
                          priority: imagePriority,
                          template,
                        } as AkamaiImageProps
                      }
                      src={video.thumbnailUrl}
                    >
                      <div className="absolute bottom-2 right-2">
                        <Badge ignoreTheme>
                          {formatVideoDuration(video?.duration)}
                        </Badge>
                      </div>
                    </CardMedia>
                    <CardContent>
                      <CardTitle
                        title={video?.videoName}
                        titleTypographyProps={{
                          as: hasTitle && !headingPriority ? 'h3' : 'h2',
                          // `button2` doesn't apply colors by default
                          // See: https://ids.motortrend.com/?path=/docs/components-typography--documentation#typography-styles
                          className: 'text-neutral-2 dark:text-neutral-7',
                          maxLines: 3,
                          variant: 'button2',
                        }}
                      />
                    </CardContent>
                  </Card>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </LayoutGridContent>
    </LayoutGrid>
  );
};

export default withErrorBoundary(FilmStrip, {
  FallbackComponent: NoUiErrorFallback,
});
