import { type FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { graphql } from '@/lib/gql';
import { NoUiErrorFallback } from '@/components/ErrorFallback';
import TrendingWidget from '@/components/TrendingWidget';
import AdModule from '@/modules/AdModule';
import CarMatchBannerModule from '@/modules/CarMatchBanner';
import FluidAdModule from '@/modules/FluidAdModule/FluidAdModule';

import EventsCalendar from './EventsCalendar';
import EventsSponsorship from './EventsSponsorship';
import { type ModuleSelectorProps } from './ModuleSelector.props';

// TODO API: get options for ad modules
export const CuratedModuleFragment = graphql(/* GraphQL */ `
  fragment CuratedModule on CurationModules {
    moduleType
    ...AdModule
    ...FluidAdModule
    ...EventsCalendarModule
    ...EventsSponsorshipModule
  }
`);

const renderCurationModule = (
  moduleInfo: ModuleSelectorProps['modules'][0],
  pageTargeting: ModuleSelectorProps['pageTargeting'],
) => {
  const { moduleType, options } = moduleInfo;

  switch (moduleType) {
    case 'ad':
      // TODO: pass fragment, not destructured data
      return <AdModule options={options} pageTargeting={pageTargeting} />;
    case 'fluidAd':
      // TODO: pass fragment, not destructured data
      return (
        <ErrorBoundary FallbackComponent={NoUiErrorFallback}>
          <FluidAdModule options={options} pageTargeting={pageTargeting} />
        </ErrorBoundary>
      );
    case 'relatedContent':
      return <TrendingWidget />;
    case 'carMatchBanner':
      return (
        <ErrorBoundary FallbackComponent={NoUiErrorFallback}>
          <CarMatchBannerModule />
        </ErrorBoundary>
      );
    case 'seoLinksBox':
    case 'eventsCalendar':
      return (
        <ErrorBoundary FallbackComponent={NoUiErrorFallback}>
          <EventsCalendar moduleData={moduleInfo} />
        </ErrorBoundary>
      );
    case 'eventsSponsorship':
      return (
        <ErrorBoundary FallbackComponent={NoUiErrorFallback}>
          <EventsSponsorship moduleData={moduleInfo} />
        </ErrorBoundary>
      );

    case 'findYourNextCar':
    case 'newsletterSignup':
    case 'hotrod newsletterSignup':
    case 'trucktrend newsletterSignup':
    case 'noboringcars newsletterSignup':
    case 'detroitTradingWidget':
    case 'surveyAd':
    case 'ValvolineBadge':
    case 'reviewerSpotlight':
    case 'popularTopics':
      return null;

    default:
      console.error(`Invalid module type: ${moduleType}`);
      return;
  }
};

const ModuleSelector: FunctionComponent<ModuleSelectorProps> = ({
  modules,
  pageTargeting,
}) => {
  if (!modules) {
    return null;
  }

  return modules.map((moduleData) => {
    return renderCurationModule(moduleData, pageTargeting);
  });
};

export default ModuleSelector;
